<div class="modal-content-wrapper confirmation-wrapper">
    <div class="modal-header bg-info">
        <h4 class="modal-title" id="myModalLabel">{{context.title?context.title: 'Confirmation'}}</h4>
        <button class="close" (click)="closeModal(false)" type="button" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="clearfix div-section-heading">
            <div class="radioConfirmWatch clearfix row p-4">
                <div class="RadioBtnNumberPeople t-center description"  [innerHTML]="context.content?context.content:'Are you sure you want to continue?'"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
            <button style="min-width: 85px" class="btn-secondary custom-btn mr-3 ps-btn"  (click)="closeModal(false)">
              <i class="fas fa-times mr-2"></i>
              {{context.cancel_btn_lbl? context.cancel_btn_lbl: 'Cancel'}}</button>
            <button *ngIf="!disableSecondaryButton" style="min-width: 85px"   (click)="closeModal(true)"   [disabled]="confirmation_busy" class="btn-primary custom-btn mr-3 ps-btn {{confirmation_busy?'ajax-container-loading':'' }}"  type="button">
              <i class="fas fa-check mr-2"></i>
              {{context.confirm_btn_lbl?context.confirm_btn_lbl: 'Confirm'}}</button>
    </div>
</div>
