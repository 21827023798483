import {Component,   OnInit,   HostListener, AfterViewInit} from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-modal',
    template: '<ng-container></ng-container>'
})

export class BaseModal implements OnInit, AfterViewInit{

    callBackOnClose: any;
    dataOnClose: any;
    parentDialog: any;
    parent: any;
    hideParentOnClose: any;



    @HostListener('document:click', ['$event']) handleClick(event: any){

        // -- below are the classes associated with popup wrappers so, we might need to avoid confliction of below classes
        if(event.target['className'] === 'modal fade in' || event.target['className'] === 'modal-dialog' || event.target.dataset['closeModal']){
            let result = this.dataOnClose? this.dataOnClose: true;
            this.close(result);
        }

    }

    constructor() {

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.setWidthAttr();
    }

    close(result: any, data?:any) {

        if(this.callBackOnClose) {
            this.callBackOnClose.call();
        }

        // Code added to remove the modal class from the parent page to fix the scroll bar issue.
        $('body').removeClass("modal-open");


        // @ts-ignore
        this['dialog'].close(result, 500);
        this.hideDialog();
        // -- setting hideParentOnClose will not revealed hidden parent popup [eg. used in confirmation dailog with 3 level popup stacks]
        if(!this.hideParentOnClose) {
               this.showParentDialog();
        }

       // this['dialog'].destroy();
    }

    closeAll() {
        // @ts-ignore
      this['dialog'].close();
        if(this.parent) {
            this.parent.closeAll();
        }
    }

    // show parent modal
    showParentDialog() {
        if(this.parent) {
            this.parentDialog = this.parent.dialog;
        }
        if(this.parentDialog) {
            $(this.parentDialog.overlayRef['location']['nativeElement']).css('display', 'block');
        }

    }

    // hide parent modal
    hideParentDialog(parentContext?: any): void {
        // -- in case the context
        if(parentContext) {
            this.parentDialog = parentContext.dialog;
        }else if(this.parent) {
            this.parentDialog = this.parent.dialog;
        }

        if(this.parentDialog) {
            $(this.parentDialog.overlayRef['location']['nativeElement']).css('display', 'none');
        }
    }

    hideDialog(): void {
        // @ts-ignore
      $(this['dialog'].overlayRef['location']['nativeElement']).css('display', 'none');
    }

    showDialog() {
            // @ts-ignore
      $(this['dialog'].overlayRef['location']['nativeElement']).css('display', 'block');
    }

    setWidthAttr() {
        // setting up exact popup width for mobile-view by covering 100% view with 20px margin on left and right
        // as written at responsive.css with margin: 20px;
        if(window.screen.width < 992) {
            // @ts-ignore
          $(this['dialog'].overlayRef['location']['nativeElement']).find('.db-new-popup').css('width', (window.screen.width - 40) + 'px');
        }
    }

}
