import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BaseModal} from "../../../../shared/components/modal/base-modal";
import {CloseGuard, DialogRef, ModalComponent, ModalContext} from "ngx-modialog-7";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {CommonService} from "../../../../shared/services/common.service";
import {MemberService} from "../../../services/member.service";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-benefit-details-popup',
  templateUrl: './benefit-details-popup.component.html',
  styleUrls: ['./benefit-details-popup.component.scss']
})
export class BenefitDetailsPopupComponent extends BaseModal implements OnInit, AfterViewInit, CloseGuard, ModalComponent<ModalContext> {

  data: any = {};
  constructor(public dialog: DialogRef<ModalContext>,
              public commonService: CommonService,
              public memberService: MemberService,
              private toastr: ToastrService,
              private router: Router,
              public sanitizer: DomSanitizer,
  ) {
    super();
    const cntx: any = this.dialog.context;
    console.log(cntx);
    this.data = cntx.data
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  closeModal(result: any) {
    // this.router.navigateByUrl('/manage-card/add-card-ok');
    this.close(result);
  }

  beforeDismiss(): boolean {
    return true;
  }

  beforeClose(): boolean {
    return true;
  }
}
