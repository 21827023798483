import { Pipe, PipeTransform } from '@angular/core';
import {CurrencyPipe, formatCurrency} from '@angular/common';

@Pipe({
  name: 'monetaryFormatter'
})
export class MonetaryFormatterPipe  implements PipeTransform {

  constructor(private curPipe: CurrencyPipe) {}
  transform(value: any, showDecimal?: boolean): string {
    /* Formating currency in 2 ways
      Kr ## ### ###,## [with decimal]
      Or
      Kr ## ### ### [without decimal]
     */
    // identifying if decimals are required to display or not.

    if (!value){
      value = 0;
    }
    const degitInfo = showDecimal ? '1.0-2' : '1.0-0';
    let defaultFormated: any = this.curPipe.transform(value, 'Kr ',  'symbol' , degitInfo);
    // replacing commas with spaces as per requirement
    const regex = new RegExp(',', 'g');
    defaultFormated = defaultFormated.replace(regex, ' ');
    // replacing decimal (.) with comma(,) as per requirement
    defaultFormated = defaultFormated.replace('.', ',');
    return defaultFormated;

  }
}
