import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {DialogRef, ModalComponent, CloseGuard} from 'ngx-modialog-7';
import {BSModalContext} from 'ngx-modialog-7/plugins/bootstrap';
import {Router} from '@angular/router';
import {BaseModal} from '../modal/base-modal';
// import {BaseModal} from '../../modal/base-modal';



declare var $: any;
export class ConfirmationPopuppContext extends BSModalContext {
    title: any;
    content: any;
    cancel_btn_lbl: any;
    confirm_btn_lbl: any;
    hide_parent_on_confirmation: any;
}


@Component({
    selector: 'app-confirmation-popup',
    styleUrls: ['./confirmation-popup.style.scss'],
    templateUrl: 'confirmation-popup.component.html',
})

export class ConfirmationPopupComponent extends BaseModal implements AfterViewInit, CloseGuard, ModalComponent<ConfirmationPopuppContext> {
    context: any;
    title: any;
    content: any;
    cancel_btn_lbl: any;
    confirm_btn_lbl: any;
    confirmation_busy = false;
    disableSecondaryButton = false;
    constructor(
        public dialog: DialogRef<ConfirmationPopuppContext>,
        private router: Router,
        private elem: ElementRef,
        private fb: FormBuilder,
    ) {
        super();
        this.context = dialog.context;
        if(this.context['parentDialog']) {
            this.parentDialog =  this.context['parentDialog'];
        }
        if(this.context['disableSecondaryButton']) {
          this.disableSecondaryButton =  this.context['disableSecondaryButton'];
        }
        this.hideParentDialog();

    }

    ngAfterViewInit() {
        setTimeout(() => {
            $(this.elem.nativeElement).find('.messagepopup-content-container').css('opacity', 1);
        });

    }
    // ----------------------

    closeModal(result: any) {
        // -- if user confirms and there is hide_parent option then the hideParentONClose is set to true;
        if(result && this.context.hide_parent_on_confirmation) {
            this.hideParentOnClose = true;
        }

        if(this.context['httpCallAfterConfirmation'] && result) {
            this.confirmation_busy = true;
            this.context['httpCallAfterConfirmation'].subscribe((data: any) => {
                // this.dialog.close(result); // making the original close dialog to deminish the blank gape to show the parent dislog
                // if(data['success']) {
                    this.close(true);
                // }
            });
        } else {
            this.close(result);
        }

    }

    beforeDismiss(): boolean {
        return true;
    }

    beforeClose(): boolean {
        return true;
    }

}
