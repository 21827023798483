import {Component, Inject, OnInit} from '@angular/core';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest
} from '@azure/msal-browser';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {b2cPolicies} from './auth-config';
import {CommonService} from "./modules/shared/services/common.service";
import {SharedService} from "./modules/shared/services/shared.service";
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'nabonett-ui';
  isIframe = false;
  loginDisplay = false;
  private readonly destroying$ = new Subject<void>();
  authAzureId: any;
  authEmail: any;

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private commonService: CommonService,
              private shareService: SharedService,
              private router: Router
              ) { }

  ngOnInit() {
      this.isIframe = window !== window.parent && !window.opener;

      /**
       * You can subscribe to MSAL events as shown below. For more info,
       * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
       */
      this.msalBroadcastService.inProgress$
          .pipe(filter((status: InteractionStatus) => status != null),
              takeUntil(this.destroying$))
          .subscribe((interactionStatus: any) => {
            // console.log('interactionStatus', interactionStatus);
          });

      // LOGIN SUCCESS EVENT
      this.msalBroadcastService.msalSubject$
          .pipe(
              // Optional filtering of events
              filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
              takeUntil(this.destroying$)
          )
          .subscribe((result: EventMessage) => {
              //// console.log('EventType.LOGIN_SUCCESS', this.authService.instance.getAllAccounts());
              this.afterLoginProcess();
              if (result.error instanceof AuthError) {
                  // Send user to reset their password
                  // console.log("Password reset called: " + result.error);
                  //this.resetPassword();
              }
          });

    // In case login failed
    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {
        // console.log('EventType.LOGIN_FAILURE', result)
        setTimeout(() => {
          this.shareService.logout();
        }, 3000)
      });
  }

  afterLoginProcess() {
    const accounts: any = this.authService.instance.getAllAccounts();
     if(accounts && accounts.length > 0){
       const authAc = accounts[0];
       this.commonService.setAuthAccount(authAc);
       this.authAzureId = authAc.localAccountId;
       this.authEmail = authAc.name;

       this.commonService.updateLastLogin(this.authAzureId, this.authEmail).subscribe(response => {
         // console.log('Last login update', response);
       })

       /*this.commonService.getUserInfo().subscribe((result: any) => {
         if (result.isSuccess == true) {
           const data: any =  result.data;
           if(data.userDetails){
              const userInfo: any = data.userDetails;
              this.commonService.setAuthUser(userInfo);
              if(userInfo.isPersonNationalIdentityAcquired) {
                if(Array.isArray(data.membershipdata) && data.membershipdata.length ){
                  // -- HAS MEMBERSHIP DATA
                  this.commonService.setAuthMemberships(data.membershipdata);

                }
                if(Array.isArray(data.ownerships) && data.ownerships.length ){
                  // -- HAS OWNERSHIP DATA
                  this.commonService.setAuthOwnerships(data.ownerships);

                }
              }

             // this.router.navigate(['/account/overview']);
           }



         }
       }, error => {
       });*/
     }

  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                  });
          } else {
              this.authService.loginPopup(userFlowRequest)
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                  });
          }
      } else {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
          } else {
              this.authService.loginRedirect(userFlowRequest);
          }
      }
  }

  logout() {
    this.shareService.logout();
  }

  editProfile() {
  }

  resetPassword() {
      const resetPasswordFlowRequest = {
          scopes: ['openid'],
          authority: b2cPolicies.authorities.resetPassword.authority,
      };

      this.login(resetPasswordFlowRequest);
  }

  onDestroy(): void {
      this.destroying$.next(undefined);
      this.destroying$.complete();
  }
}
