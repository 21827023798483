import { Component, OnInit } from '@angular/core';
import {NgxSpinnerService} from "ngx-spinner";
import {SharedService} from "../../modules/shared/services/shared.service";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private sharedService: SharedService
  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    localStorage.clear();
    sessionStorage.clear();
    this.sharedService.logout();
  }

}
