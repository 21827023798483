import { Injectable } from '@angular/core';
import {API_TYPE, HttpHelperService} from "../../../services/http-helper.service";

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  constructor(
    private httpHelper: HttpHelperService,
  ) { }

  getUserInfo() {
    return this.httpHelper.get('user/getuser', {}, API_TYPE.USERMANAGER_API);
  }
  getAllUserRoles() {
    return this.httpHelper.get('user/GetAllUserRoles', {}, API_TYPE.USERMANAGER_API);
  }


}
