<div class="modal-content-wrapper">
 <!-- <div class="modal-header bg-info">
    <h4 class="modal-title" id="myModalLabel">Legg til nytt</h4>
    <button class="close" (click)="closeModal(true)" type="button" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>-->
  <div class="modal-body benefit-details-container p-0" >

    <div class="row">
      <div class="col-md-6 ben-col pl-5-rem">
        <h2 class="ben-name" [innerHTML]="data?.name"></h2>
        <h1 class="ben-teaser" [innerHTML]="data?.teaser"></h1>
        <div class="mt-10 ben-desc-html" [innerHTML]="data?.description">
        </div>
        <a href="{{data?.urlWebsite}}" target="_blank" style="display: inline-block; margin-left: 0 !important;" class="btn-primary custom-btn ml-3 ps-btn mt-4">
          <i class="bi bi-box-arrow-up-right pr-2"></i> Gå til {{data?.name}}</a>
      </div>
      <div class="col-md-6 pr-5-rem ben-col ben-bg-highlighted">
        <div class="ben-close-btn">
          <a href="javascript:void(0)" (click)="close(true)"><i class="bi bi-x-circle"></i></a>
        </div>
        <h1 class="ben-teaser">Fordel</h1>
        <div class="mt-10 ben-desc-html" [innerHTML]="data?.benefit">
        </div>
      </div>
    </div>


  </div>
 <!-- <div class="modal-footer">

    <div style="display: flex">
      <button class="custom-btn ps-btn ps-btn&#45;&#45;with-opacity" (click)="closeModal(false)">
        <i class="fa-solid fa-xmark mr-2"></i>
        Lukk</button>

    </div>

  </div>-->
</div>


