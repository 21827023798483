import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import {API_TYPE, HttpHelperService} from "./http-helper.service";
import {ArticleService} from "../modules/account/services/article.service";
import {BORI_BBL_COMP_ID} from "../modules/account/services/member.service";

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpHeaderInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private httpHelper: HttpHelperService, private  articleService: ArticleService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
      // EXCEPTIONAL CONDITION FOR VERIFY APP API CALLS
      let isVerifyAppAPI = true;
      if(req.url.includes(environment.apiBaseUrl)){
        isVerifyAppAPI = false;
      }
      const apiType = this.httpHelper.getApiTypeFromUrl(req.url);
        return this.authService.checkAndAquireToken().pipe(
            switchMap((response: any) => {
                var newHeaderUpdate = this.updateAuthHeader(response.accessToken, isVerifyAppAPI, apiType);
                return next.handle(req.clone(newHeaderUpdate));
            }),
            catchError(err => {
                // console.log('Http Name', err.name);
                const errStr = err.toString();
                if (err.name === "InteractionRequiredAuthError" || err.name ==="BrowserAuthError") {
                    return this.authService.logoutUser();
                }else if(err.name == 'ServerError' && errStr.includes('redirect_uri_mismatch')){
                    return this.authService.logoutUser();
                }
                return throwError(err);
            }));
    }

    // This method add token to request headers
    updateAuthHeader(access_token?: string, isVerifyAppAPi?: boolean, apiType?: any): any {
        // Update headers with access token

        const headerObj: any = {};
        const apiTypesWithCompanyId = [API_TYPE.ARTICLE_API, API_TYPE.MEMBER_API];
        const apiTypesWithoutSubscription = [API_TYPE.USERMANAGER_API, API_TYPE.OWNERSHIP_API];

        let compId =   this.articleService.getCompanyForAPIHeader();
        if(!compId && apiType == API_TYPE.MEMBER_API){
          compId = BORI_BBL_COMP_ID;
        }
        if (apiTypesWithCompanyId.includes(apiType)){
          headerObj['companyId'] = compId;
        }else if(!apiTypesWithoutSubscription.includes(apiType)){
          headerObj['subscription-key'] =  isVerifyAppAPi?environment.verifyApp_subscriptionKey : environment.subscriptionKey;
        }

        if(access_token){
          headerObj['Authorization'] = 'Bearer ' + access_token;
        }
        const newHeaderUpdate = {
          setHeaders: headerObj
        };
        return newHeaderUpdate;
    }
}
