import { Injectable, SkipSelf } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpHelperService {
    apiBaseUrl = environment.apiBaseUrl;
    articlesApiBaseUrl = environment.articlesApiBaseUrl;
    TYPE = API_TYPE;

    constructor(public httpClient: HttpClient) {
    }

    private _getHeader() {
        const httpOptions: any = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return httpOptions;
    }

    private _getHttpOptions(additionalHeaders?: any) {
      let headerItemsObj: any = {
        'Content-Type': 'application/json'
      };
      if(Array.isArray(additionalHeaders)) {
        additionalHeaders.forEach((item: any) => {
          if(item.key && item.value) {
            headerItemsObj[item.key + ''] = item.value + '';
          }
        })
      }
      const httpHeadersObj = new HttpHeaders(headerItemsObj);
      return { headers: httpHeadersObj };
    }

    private _handleError(errResp: HttpErrorResponse) {
        const errorObj = { type: 'unknown', error_response: errResp.error, message: '', field_error: '' };
        if (errResp.error instanceof ErrorEvent) {
            errorObj.message = errResp.error.message;
            // A client-side or network error occurred. Handle it accordingly.
            // console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            /* console.error(
                 `Backend returned code ${errResp.status}, ` +
                 `body was: ${errResp.error}`);*/
        }

        if (errResp.status === 401) {
            // -- unauthorised
            // localStorage.removeItem('auth_provider');
            // localStorage.removeItem('access_token');
            // localStorage.removeItem('user_data');
            //  this.router.navigate(['login']);
            //window.location.href = location.origin + '/';

            // ToDo -- Varun: Need to figure out what is to be done here
            // If API token is invalid then logout user and prompt for relogin
            //this.authService.logout();
            //window.location.href = location.origin + '/';
            return throwError({});
        }

        // return an observable with a user-facing error message
        if (errResp.error != null && errResp.error['errors']) {
            errorObj['type'] = 'field_error';
            errorObj['field_error'] = errResp.error['errors'];
        }
        return throwError(errorObj);
    }


    public get(url: string, params?: any, apiType?: any) {
        let httpUrl = this.apiBaseUrl + url;
        if (apiType === API_TYPE.ARTICLE_API){
          httpUrl = this.articlesApiBaseUrl + url;
        }else if (apiType === API_TYPE.MEMBER_API){
          httpUrl = environment.verifyApp_apiBaseUrl + url;
        }else if (apiType === API_TYPE.USERMANAGER_API){
          httpUrl = environment.userManagerApiBaseUrl + url;
        }else if (apiType === API_TYPE.OWNERSHIP_API){
          httpUrl = environment.ownershipApiBaseUrl + url;
        }
        const httpOptions: any = this._getHttpOptions();
        if (params) {
          httpOptions['params'] = params;
        }
        return this.httpClient.get(httpUrl, httpOptions).pipe(
            // retry(3), // retry request can be set upto n times
            catchError(this._handleError),
            shareReplay()
        );
    }

      public getResponseAsBuffer(url: string, params?: any, apiType?: any) {
        let httpUrl = this.apiBaseUrl + url;
        if (apiType === API_TYPE.ARTICLE_API){
          httpUrl = this.articlesApiBaseUrl + url;
        }

        const httpOptions: any = { responseType: 'arraybuffer' };
        if (params) {
          httpOptions['params'] = params;
        }
        return this.httpClient.get(httpUrl, httpOptions).toPromise();
      }

  public getResponseAsString(url: string, params?: any, apiType?: any) {
    let httpUrl = this.apiBaseUrl + url;
    if (apiType === API_TYPE.ARTICLE_API){
      httpUrl = this.articlesApiBaseUrl + url;
    }

    const httpOptions: any = { responseType: 'text' };
    if (params) {
      httpOptions['params'] = params;
    }
    return this.httpClient.get(httpUrl, httpOptions).toPromise();
  }


  public getWithExtraHeaders(url: string, headers: any, params?: any){
    const httpUrl = this.apiBaseUrl + url;
    const httpOptions: any = this._getHttpOptions(headers);
    if (params) {
      httpOptions['params'] = params;
    }
    return this.httpClient.get(httpUrl, httpOptions).pipe(
      catchError(this._handleError),
      shareReplay()
    );
  }

  public getBufferWithExtraHeaders(url: string, headers: any, params?: any){
    const httpUrl = this.apiBaseUrl + url;
    const httpOptions: any = this._getHttpOptions(headers);
    httpOptions['responseType']= 'arraybuffer' ;

    if (params) {
      httpOptions['params'] = params;
    }
    return this.httpClient.get(httpUrl, httpOptions).pipe(
      catchError(this._handleError),
      shareReplay()
    );
  }

    public post(url: string, bodyObj?: object, apiType?: any) {
        let httpUrl = this.apiBaseUrl + url;
        if (apiType === API_TYPE.ARTICLE_API){
          httpUrl = this.articlesApiBaseUrl + url;
        }else if (apiType === API_TYPE.MEMBER_API){
          httpUrl = environment.verifyApp_apiBaseUrl + url;
        }else if (apiType === API_TYPE.USERMANAGER_API){
          httpUrl = environment.userManagerApiBaseUrl + url;
        }else if (apiType === API_TYPE.OWNERSHIP_API){
          httpUrl = environment.ownershipApiBaseUrl + url;
        }else if (apiType === API_TYPE.SIGNVERIFY_API){
          httpUrl = environment.signVerifyApiBaseUrl + url;
        }

        const httpOptions = this._getHttpOptions();
        return this.httpClient.post(httpUrl, bodyObj, httpOptions).pipe(
            // retry(3), // retry request can be set upto n times
            catchError(this._handleError),
            shareReplay()
        );
    }

  public post_verifyApp(url: string, bodyObj?: object) {
    const httpUrl = environment.verifyApp_apiBaseUrl + url;
    const httpOptions = this._getHttpOptions();
    return this.httpClient.post(httpUrl, bodyObj).pipe(
      // retry(3), // retry request can be set upto n times
      catchError(this._handleError),
      shareReplay()
    );
  }

  public get_verifyApp(url: string, params?: any) {
    const httpUrl = environment.verifyApp_apiBaseUrl + url;
    const httpOptions: any = this._getHttpOptions();
    if (params) {
      httpOptions['params'] = params;
    }
    return this.httpClient.get(httpUrl, httpOptions).pipe(
      // retry(3), // retry request can be set upto n times
      catchError(this._handleError),
      shareReplay()
    );
  }


    public delete(url: string, params?: object, apiType?: any) {
        let httpUrl = this.apiBaseUrl + url;
        if (apiType === API_TYPE.ARTICLE_API){
          httpUrl = this.articlesApiBaseUrl + url;
        }else if (apiType === API_TYPE.MEMBER_API){
          httpUrl = environment.verifyApp_apiBaseUrl + url;
        }else if (apiType === API_TYPE.OWNERSHIP_API){
          httpUrl = environment.ownershipApiBaseUrl + url;
        }
        const httpOptions: any = this._getHttpOptions();
        if (params) {
            httpOptions['params'] = params;
        }
        return this.httpClient.delete(httpUrl, httpOptions).pipe(
            catchError(this._handleError),
            shareReplay()
        );
    }


    public put(url: string, bodyObj?: object) {
      const httpUrl = this.apiBaseUrl + url;
      const httpOptions = this._getHttpOptions();
      return this.httpClient.put(httpUrl, bodyObj, httpOptions).pipe(
        // retry(3), // retry request can be set upto n times
        catchError(this._handleError),
        shareReplay()
      );
    }


  public putOld(url: string, params?: object) {
        const httpUrl = this.apiBaseUrl + url;
        const httpOptions = this._getHeader();
        if (params) {
            httpOptions['params'] = params;
        }
        return this.httpClient.put(httpUrl, httpOptions).pipe(
            catchError(this._handleError),
            shareReplay()
        );
    }

    public getCustom(fullUrl: string, params?: any) {
        const httpOptions: any = this._getHttpOptions();
        if (params) {
            httpOptions['params'] = params;
        }
        return this.httpClient.get(fullUrl, httpOptions).pipe(
            // retry(3), // retry request can be set upto n times
            catchError(this._handleError)
        );
    }


  public getApiTypeFromUrl(URLStr: any){
    let apiType = this.TYPE.DEFAULT;
    if (URLStr){
      if(URLStr.startsWith(environment.articlesApiBaseUrl)){
        apiType = this.TYPE.ARTICLE_API;
      }else if(URLStr.startsWith(environment.userManagerApiBaseUrl)){
        apiType = this.TYPE.USERMANAGER_API;
      }else if(URLStr.startsWith(environment.ownershipApiBaseUrl)){
        apiType = this.TYPE.OWNERSHIP_API;
      }else if(URLStr.startsWith(environment.verifyApp_apiBaseUrl + 'membership/')){
        apiType = this.TYPE.MEMBER_API;
      }else if(URLStr.startsWith(environment.signVerifyApiBaseUrl)){
        apiType = this.TYPE.SIGNVERIFY_API;
      }
    }
    return apiType;
  }

  public postTextContentType(url: string, bodyObj?: object) {
    let httpUrl = environment.verifyApp_apiBaseUrl + url;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.httpClient.post(
      httpUrl,
      bodyObj,
      { headers, responseType: 'text'}
    ).pipe(catchError(this._handleError));
  }

}

export const API_TYPE = {
  DEFAULT: 'DEFAULT',
  ARTICLE_API: 'ARTICLE_API',
  MEMBER_API: 'MEMBER_API',
  USERMANAGER_API: 'USERMANAGER_API',
  OWNERSHIP_API: 'OWNERSHIP_API',
  SIGNVERIFY_API: 'SIGNVERIFY_API'
};
