import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {CommonService} from "../../../shared/services/common.service";
import {UserManagerService} from "../../../shared/services/user-manager.service";
import {MemberService} from "../../../account/services/member.service";
import {OwnershipService} from "../../../shared/services/ownership.service";

@Component({
  selector: 'app-mineside-redirect',
  templateUrl: './mineside-redirect.component.html',
  styleUrls: ['./mineside-redirect.component.scss']
})
export class MinesideRedirectComponent implements OnInit {

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private userManagerService: UserManagerService,
    private memberService: MemberService,
    private ownershipService: OwnershipService
    ) {
    // this.router.navigateByUrl('/account/profile?redirectDef=1');
    console.log('MinesideRedirectComponent')
    this.loadData();
  }

  loadedApi1 = false;
  loadedApi2 = false;
  loadedApi3 = false;
  loadedApi4 = false;
  loadData() {
    this.spinner.show();
   /* this.commonService.getUserInfo().subscribe((result: any) => {
      this.commonService.setProfileLoading(false);
      this.spinner.hide();
      if (result['isSuccess'] == true) {
        const data: any = result['data'];
        const userInfo: any = data.userDetails;
        const userRoles: any = data.userRoles;
        this.commonService.setAuthUser(userInfo);

        if(userInfo.isPersonNationalIdentityAcquired) {
          if(Array.isArray(data.membershipdata) && data.membershipdata.length ){
            // -- HAS MEMBERSHIP DATA
            this.commonService.setAuthMemberships(data.membershipdata);

          }
          if(Array.isArray(data.ownerships) && data.ownerships.length ){
            // -- HAS OWNERSHIP DATA
            this.commonService.setAuthOwnerships(data.ownerships);

          }
        }
       /!* if(!this.userInfo['consentSubmitted']) {
          this.openConsentPopup();
        }*!/



      } else {
        // console.log("Failed to get user info");
        this.router.navigate(['account/standby'])
      }
    }, error => {
      this.commonService.setProfileLoading(false);
      this.spinner.hide();
    });*/
    // --- LOADING REQUIRED DEFAULT DATA SIMULTANEOUSLY
    /* #1. LOADING USER INFO */
    this.userManagerService.getUserInfo().subscribe((response: any) => {
      this.loadedApi1 = true;
      this.commonService.setAuthUser(response);
      this.checkIfAllRequiredDataIsLoaded();
    }, error => {
      this.loadedApi1 = true;
      console.log('Error - userManagerService.getUserInfo()', error);
      this.checkIfAllRequiredDataIsLoaded();
    });
    /* #2. LOADING USER ROLES */
    this.userManagerService.getAllUserRoles().subscribe((response: any) => {
      this.loadedApi2 = true;
      this.commonService.setAuthRoles(response);
      this.checkIfAllRequiredDataIsLoaded();
    }, error => {
      this.loadedApi2 = true;
      console.log('Error - userManagerService.getAllUserRoles()', error);
      this.checkIfAllRequiredDataIsLoaded();
    });
    /* #3. LOADING ASSOCIATED MEMBERSHIPS */
    this.memberService.getMemberships().subscribe((response: any) => {
      this.loadedApi3 = true;
      this.commonService.setAuthMemberships(response);
      this.checkIfAllRequiredDataIsLoaded();
    }, error => {
      this.loadedApi3 = true;
      console.log('Error - memberService.getMemberships()', error);
      this.checkIfAllRequiredDataIsLoaded();
    });
    // #4. LOADING ASSOCIATED OWNERSHIPS
    this.ownershipService.getUserOwnerships().subscribe((response: any) => {
      this.loadedApi4 = true;
      this.commonService.setAuthOwnerships(response);
      this.checkIfAllRequiredDataIsLoaded();
    }, error => {
      this.loadedApi4 = true;
      console.log('Error - ownershipService.getUserOwnerships()', error);
      this.checkIfAllRequiredDataIsLoaded();
    });
  }



  checkIfAllRequiredDataIsLoaded() {
    if(this.loadedApi1 && this.loadedApi2 && this.loadedApi3 && this.loadedApi4){
      this.commonService.redirectToDefaultPage();
      this.commonService.setProfileLoading(false);
      this.spinner.hide();
    }
  }

  ngOnInit(): void {
  }

}
