import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
@Component({
  selector: 'app-add-card-error',
  templateUrl: './add-card-error.component.html',
  styleUrls: ['./add-card-error.component.scss']
})
export class AddCardErrorComponent implements OnInit {

  constructor(private router: Router,private toastr: ToastrService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    console.log('manage-card-error-component');
    const redirectURL = localStorage.getItem('managecard')
    console.log(redirectURL);
    this.toastr.error('Feil ved innlegging av kort', 'Legg til nytt');
    if(redirectURL){
      localStorage.setItem('add-card','error');
      this.router.navigateByUrl(redirectURL);
    }
    else{
      this.router.navigateByUrl('https://minside.nabonett.no');
    }
  }

}
