import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import {RouterModule, Routes} from "@angular/router";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { GoogleMapsModule } from '@angular/google-maps';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './auth-config';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalModule, MsalRedirectComponent
} from '@azure/msal-angular';
import { SecureComponent } from './secure/secure.component';
import {SharedModule} from "./modules/shared/shared.module";
import {AuthGuard} from "./modules/shared/guards/auth.guard";
import {ReactiveFormsModule} from "@angular/forms";
import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar2';
import { HttpHeaderInterceptor } from './services/httpinterceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HttpHelperService } from './services/http-helper.service';
import { AuthService } from './services/auth.service';
import {ModalModule} from "ngx-modialog-7";
import {BootstrapModalModule} from "ngx-modialog-7/plugins/bootstrap";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {AgmCoreModule} from "@agm/core";
import { MinesideRedirectComponent } from './modules/default/components/mineside-redirect/mineside-redirect.component';
import {NgxSpinnerModule} from "ngx-spinner";
import { LogoutComponent } from './components/logout/logout.component';
import { ManageCardComponent } from './modules/manage-card/manage-card.component';
import { AddCardOkComponent } from './modules/manage-card/add-card-ok/add-card-ok.component';
import { AddCardErrorComponent } from './modules/manage-card/add-card-error/add-card-error.component';
import { AddCardVerificationErrorComponent } from './modules/manage-card/add-card-verification-error/add-card-verification-error.component';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
 export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
  };
}

const routes: Routes = [
    { path: '', component: MinesideRedirectComponent, canActivate: [ MsalGuard ] },
    { path: 'manage-card/add-card-ok', component: AddCardOkComponent},
    { path: 'manage-card/add-card-error', component: AddCardErrorComponent},
    { path: 'manage-card/add-card-verification-error', component: AddCardVerificationErrorComponent},
    { path: 'auth/logout', component: LogoutComponent},
    { path: 'home', loadChildren: () => import('./modules/default/default.module').then(m => m.DefaultModule) },  // , canActivate: [ MsalGuard ]
    { path: 'bankid', loadChildren: () => import('./modules/bankid/bankid.module').then(m => m.BankidModule), canActivate: [ MsalGuard ] },  // , canActivate: [ MsalGuard ]
    { path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule), canActivate: [ MsalGuard ] },
    { path: '**', redirectTo: 'home' },
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


@NgModule({
  declarations: [
    AppComponent,
    SecureComponent,
    MinesideRedirectComponent,
    LogoutComponent,
    ManageCardComponent,
    AddCardOkComponent,
    AddCardErrorComponent,
    AddCardVerificationErrorComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBW_N8-gjbvjQGoicCDZUYAIVIRNa89Vbg'
    }),
    RouterModule.forRoot(routes),
    SharedModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),

    BootstrapModalModule,
    NgxSpinnerModule,

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpHeaderInterceptor, multi: true },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    AuthService,
    HttpHelperService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
