import { Injectable } from '@angular/core';
import {API_TYPE, HttpHelperService} from "../../../services/http-helper.service";

@Injectable({
  providedIn: 'root'
})
export class OwnershipService {

  constructor( private httpHelper: HttpHelperService,
  ) { }

  getUserOwnerships() {
    return this.httpHelper.get('GetUserOwnerships', {}, API_TYPE.OWNERSHIP_API);
  }

  getOwnershipsForPersonNumber(personNumber:any) {
    return this.httpHelper.get('GetOwnershipsForPersonNumber', {personNumber}, API_TYPE.OWNERSHIP_API);
  }


}
