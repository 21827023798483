import { Injectable } from '@angular/core';
import {API_TYPE, HttpHelperService} from "../../../services/http-helper.service";
import {SESSION_KEY_AUTH_OWNERSHIPS} from "../../shared/services/common.service";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  API_TYPE = API_TYPE;
  articleBannersbyId: any = {};
  constructor(
    private httpHelper: HttpHelperService,
  ) { }

  getArticles() {
    const params = {tag: 'Published'};
    return this.httpHelper.get('Articles/GetArticles', params, API_TYPE.ARTICLE_API);
  }

  getArticleById(articleId: any) {
    return this.httpHelper.get('Articles/GetArticleData', {articleId}, API_TYPE.ARTICLE_API);
  }

  getArticleBannerImage(articleId: any) {
    return this.httpHelper.getResponseAsString('Articles/GetArticleBannerImage', {articleId}, API_TYPE.ARTICLE_API);
  }
  setCompanyForAPIHeader(companyId: any) {
    sessionStorage.setItem(SESSION_KEY_API_HEADER_COMPANY_ID, companyId);
  }
  getCompanyForAPIHeader(){
    return sessionStorage.getItem(SESSION_KEY_API_HEADER_COMPANY_ID)? sessionStorage.getItem(SESSION_KEY_API_HEADER_COMPANY_ID): 0;
  }


  setArticleBanner(id: any, bannerImgStr: any){
    this.articleBannersbyId[id] = bannerImgStr;
  }

  getArticleBanner(id: any){
    return this.articleBannersbyId[id] ? this.articleBannersbyId[id] : '';
  }

  downloadFile(articleId: any, elementId: any) {
    return this.httpHelper.get('Articles/downloadFile', {articleId, elementId},  API_TYPE.ARTICLE_API);
  }
}

export const SESSION_KEY_API_HEADER_COMPANY_ID = 'API_HEADER_COMPANY_ID'
