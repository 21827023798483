import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()
export class AuthService {
  private readonly destroying$ = new Subject<void>();
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];

   static KEY_MSL_TOKEN_RECEIVED = 'MSL_TOKEN_RECEIVED';
   static KEY_TRANS_FORCE_RELOAD_REQUIRED = 'TRANS_FORCE_RELOAD_REQUIRED';


  constructor(
      private authService: MsalService,
      private msalBroadcastService: MsalBroadcastService) {
  }

  checkAndAquireToken(): Observable<any> {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this.destroying$)
      )
      .subscribe((result: EventMessage) => {
        // // console.log("EventType.LOGIN_SUCCESS", result);
        sessionStorage.setItem(AuthService.KEY_MSL_TOKEN_RECEIVED, 'true');
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    return this.getToken();
  }

  logoutUser(): Observable<any>
  {
      sessionStorage.removeItem(AuthService.KEY_TRANS_FORCE_RELOAD_REQUIRED);
      sessionStorage.removeItem(AuthService.KEY_MSL_TOKEN_RECEIVED);
      return this.authService.logout();
  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  getToken(): Observable<any> {
    const tokenRequest = {
      account: this.authService.instance.getAllAccounts()[0],
      authority: 'https://id.nabonett.no/StyretB2CTenant.onmicrosoft.com/B2C_1_minsideNabonett',
      scopes: [environment.apiScope]
    };

    return this.authService.acquireTokenSilent(tokenRequest);
  }
}
