import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {NgxSpinnerService} from "ngx-spinner";
@Component({
  selector: 'app-add-card-ok',
  templateUrl: './add-card-ok.component.html',
  styleUrls: ['./add-card-ok.component.scss']
})
export class AddCardOkComponent implements OnInit {

  constructor(private router: Router,private toastr: ToastrService,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.show();
    console.log('manage-card-component');
    const redirectURL = localStorage.getItem('managecard')
    console.log(redirectURL);
    this.toastr.success('Kortet er lagt til', 'Legg til nytt');
    if(redirectURL){
      localStorage.setItem('add-card','ok');
      this.router.navigateByUrl(redirectURL);
    }
    else{
      this.router.navigateByUrl('https://minside.nabonett.no');
    }
  }

}
