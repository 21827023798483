export const environment = {
  production: true,
  clientId: '8cb00330-b117-413d-86e7-bd0279e6866d',
  subscriptionKey: 'd6d0ceb01133497ca0881aa72bbab0af',
  apiBaseUrl: 'https://openapi.styret.com/minside/',
  apiScope: 'https://StyretB2CTenant.onmicrosoft.com/vendorprod/vendorprod-access',

  articlesApiBaseUrl: 'https://openapi.styret.com/articles/',

  userManagerApiBaseUrl: 'https://openapi.styret.com/usermanager/',
  ownershipApiBaseUrl: 'https://openapi.styret.com/ownerships/',
  signVerifyApiBaseUrl: 'https://openapi.styret.com/sign/',

  verifyApp_apiBaseUrl: 'https://openapi.styret.com/',
  verifyApp_subscriptionKey: '438d296bf4584bfeac7167dd4f7548e3',
};
