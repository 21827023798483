import { Injectable } from '@angular/core';
import {API_TYPE, HttpHelperService} from "../../../services/http-helper.service";
import {
  BenefitDetailsPopupComponent
} from "../components/membership-benefits/benefit-details-popup/benefit-details-popup.component";
import {Modal, overlayConfigFactory} from "ngx-modialog-7";
import {BSModalContext} from "ngx-modialog-7/plugins/bootstrap";

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  API_TYPE = API_TYPE;

  constructor(
    private modal: Modal,
    private httpHelper: HttpHelperService,
  ) { }

  getMembershipBenefits(membershipId: any) {
    const params = {membershipId};
    // https://openapi.styret.com/membership/GetMembershipBenefitsById?membershipId=11
    return this.httpHelper.get('membership/GetMembershipBenefitsById', params, API_TYPE.MEMBER_API);
  }


  storeMembershipData(membershipId: any, data: any){
    const sessionKey = this.MEMBERSHIP_SESSION_KEY_PREFIX + membershipId;
    const jsonStr = JSON.stringify(data);
    sessionStorage.setItem(sessionKey, jsonStr);
  }

  getMembershipData(membershipId: any){
    const sessionKey = this.MEMBERSHIP_SESSION_KEY_PREFIX + membershipId;
    let data: any = null;
    const sessionData = sessionStorage.getItem(sessionKey);
    if(sessionData){
      data = JSON.parse(sessionData)
    }
    return data;
  }

  GetMembershipById(membershipId: any) {
    const params = {membershipId};
    return this.httpHelper.get('membership/GetMembershipById', params,API_TYPE.MEMBER_API);
  }

  deleteCard(externalCardId: any, membershipId: any) {
    const params = {externalCardId,membershipId};
    return this.httpHelper.delete('membership/DeleteCard', params,API_TYPE.MEMBER_API);
  }

  OrderCashback(obj: any) {
    return this.httpHelper.post('membership/OrderCashback', obj,API_TYPE.MEMBER_API);
  }

  UpdateMemberAccountNumber(obj: any) {
    return this.httpHelper.post('membership/UpdateMemberAccountNumber', obj,API_TYPE.MEMBER_API);
  }

  deleteMemberAccountNumber(membershipId: any) {
    const params = {membershipId};
    return this.httpHelper.delete('membership/DeleteMemberAccountNumber', params,API_TYPE.MEMBER_API);
  }

  generateAddCardIframe(cardType: any, membershipId: any) {
    const params = {cardType,membershipId};
    return this.httpHelper.postTextContentType('membership/GenerateAddCardIframe?cardType='+ cardType +'&membershipId=' + membershipId);
  }

  getMemberships() {
    return this.httpHelper.get('membership/GetMemberships', '',API_TYPE.MEMBER_API);
  }

  getMembershipsByPersonNumber(personNumber: any) {
    return this.httpHelper.get('membership/GetMembershipsByPersonNumber', {personNumber},API_TYPE.MEMBER_API);
  }

  openBenefitDetailsPopup(data: any){
    const dialog = this.modal.open(BenefitDetailsPopupComponent, overlayConfigFactory({
      dialogClass: 'modal-dialog modal-1300',
      data
    }, BSModalContext));
    dialog.result.then((result: any) => {
    });
  }
  MEMBERSHIP_SESSION_KEY_PREFIX = 'MEMB_';
}


export const BORI_BBL_COMP_ID = '894';
